<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <Dialog v-model:visible="modalVisible" modal :header="editingData ? editingData.name_branch + ' - ' + editingData.processor_name : ''" :style="{ width: '50vw' }">
            <div class="p-grid">
                <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="true">
                        <template #legend> 
                            <img :src="require('../../../../assets/icons/payment/VISA.png')" class="ddl-card-icon" /> <img :src="require('../../../../assets/icons/payment/MASTERCARD.png')" class="ddl-card-icon" />
                        </template>
                        <div class="grid p-fluid">
                            <FormInputNumber v-model="editingData.visa_mc_debit_rate" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Debito" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_rate" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-6" label="Credito" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_3" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_6" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_9" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" />                            
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_12" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_18" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" />
                            <FormInputNumber v-model="editingData.visa_mc_credit_surcharge_24" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" />
                        </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset :toggleable="true" :collapsed="true">
                        <template #legend> 
                            <img :src="require('../../../../assets/icons/payment/AMERICANEXPRESS.png')" class="ddl-card-icon" />
                        </template>
                        <div class="grid p-fluid">
                            <FormInputNumber v-model="editingData.amex_credit_rate" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-12" label="Credito" />
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_3" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="3 MSI" />
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_6" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="6 MSI" />
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_9" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="9 MSI" />                            
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_12" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="12 MSI" />
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_18" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="18 MSI" />
                            <FormInputNumber v-model="editingData.amex_credit_surcharge_24" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :prefix="'%'" wrapperClass="field col-3" label="24 MSI" />
                        </div>
                    </Fieldset>
                </div>
            </div>
            <template #footer>
                <Button :label="editingData.id ? 'Actualizar' : 'Guardar'" icon="pi pi-save" @click="updateRow" autofocus />
            </template>
        </Dialog>

        <div class="p-col-12">
            <div class="card">
                <Toast />
                <Panel header="Configuracion General de Clientes">
                    <BasicFormToolbar @new="openNew" @refresh="refresh" @list="list" :actions="['new', 'refresh', 'list']" />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Informacion General">
                                <div class="p-fluid formgrid grid">
                                    <FormDropdown
                                        label="Base de datos"
                                        :wrapperClass="'field col-4'"
                                        :options="databases"
                                        :optionLabel="'alias'"
                                        :optionValue="'name'"
                                        :filter="true"
                                        @change="searchCompaniesDatabase"
                                        v-model="entity.external_database"
                                        :clear="true"
                                        @clear="
                                            () => {
                                                entity.external_database = null;
                                                searchCompaniesDatabase();
                                            }
                                        "
                                        :tooltip="'Ingresar la base de datos del cliente, favor de consultar esta informacion con el area de sistemas'"
                                    />
                                    <FormDropdown v-model="entity.external_company" :filter="true" label="Empresa" :wrapperClass="'field col-4'" :options="CompanyByDatabase" :optionLabel="'company_name'" :optionValue="'id_company'"></FormDropdown>
                                    <FormDropdown
                                        v-model="entity.external_branch"
                                        label="Sucursal"
                                        :filter="true"
                                        :disabled="entity.external_company == 0"
                                        :wrapperClass="'field col-4'"
                                        :options="branchesByCompany"
                                        :optionLabel="'branch_name'"
                                        :optionValue="'id_branch'"
                                    ></FormDropdown>
                                </div>
                            </Fieldset>
                        </div>
                        <div style="margin-top: 1.5rem; overflow-x: scroll;"  class="col-12 scrollable-table">
                            <Fieldset :toggleable="true" legend="Resultados">
                                <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" :rowedit="true" @edited="editRow"/>
                                <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import { BWCybersourceBranchProcessor } from '../../../../models/custom/business_wallet/BWCybersourceBranchProcessor';
import FormDropdown from '../../../../components/general/FormDropdown.vue';
import BasicDatatable from '../../../../components/general/BasicDatatable.vue';
import BasicFormToolbar from '../../../../components/general/BasicFormToolbar.vue';
import DeleteDialog from '../../../../components/general/DeleteDialog.vue';
import { HeaderGrid, Rule, validate, fillObject, ErrorToast } from '../../../../utilities/General';
import Loader from '../../../../components/general/Loader.vue';
import Session from '../../../../mixins/sessionMixin';
import { UniverseTransaction } from '../../../../models/custom/business_wallet/BWUniverseTransaction';
import FormInputNumber from '../../../../components/general/FormInputNumber.vue';

export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null,
    },
    data() {
        return {
            branches: [],
            companies: [],
            entity: new BWCybersourceBranchProcessor(),
            entities: [],
            modalVisible: false,
            editingData: null,
            deleteDialog: false,
            rules: [new Rule({ name: 'external_database' })],
            validate: {
                valid: false,
                validations: {
                    external_database: null,
                },
            },
            headers: [
                new HeaderGrid('Empresa', 'name_company'),
                new HeaderGrid('Sucursal', 'name_branch'),
                new HeaderGrid('Procesador', 'processor_name'),
                new HeaderGrid('VISA Debito', 'visa_mc_debit_rate', { type: "percentage"}),
                new HeaderGrid('VISA Credito', 'visa_mc_credit_rate', { type: "percentage"}),
                new HeaderGrid('VISA 3MSI', 'visa_mc_credit_surcharge_3', { type: "percentage"}),
                new HeaderGrid('VISA 6MSI', 'visa_mc_credit_surcharge_6', { type: "percentage"}),
                new HeaderGrid('VISA 9MSI', 'visa_mc_credit_surcharge_9', { type: "percentage"}),
                new HeaderGrid('VISA 12MSI', 'visa_mc_credit_surcharge_12', { type: "percentage"}),
                new HeaderGrid('VISA 18MSI', 'visa_mc_credit_surcharge_18', { type: "percentage"}),
                new HeaderGrid('VISA 24MSI', 'visa_mc_credit_surcharge_24', { type: "percentage"}),
                new HeaderGrid('AMEX Credito', 'amex_credit_rate', { type: "percentage"}),
                new HeaderGrid('AMEX 3MSI', 'amex_credit_surcharge_3', { type: "percentage"}),
                new HeaderGrid('AMEX 6MSI', 'amex_credit_surcharge_6', { type: "percentage"}),
                new HeaderGrid('AMEX 9MSI', 'amex_credit_surcharge_9', { type: "percentage"}),
                new HeaderGrid('AMEX 12MSI', 'amex_credit_surcharge_12', { type: "percentage"}),
                new HeaderGrid('AMEX 18MSI', 'amex_credit_surcharge_18', { type: "percentage"}),
                new HeaderGrid('AMEX 24MSI', 'amex_credit_surcharge_24', { type: "percentage"}),
            ],
            databases: [],
            loading: false,
        };
    },
    components: { FormInputNumber, FormDropdown, DeleteDialog, Loader, BasicFormToolbar, BasicDatatable },
    created() {
        this.entity = new BWCybersourceBranchProcessor(this.session);
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        async updateRow() {
            this.loading = true;
            try {
                this.editingData.database = this.entity.external_database;
                this.editingData.modified_by = this.session.user;
                await this.entity.saveAdminProcessor(this.editingData);
            } catch (error) {
                alert(error.data);
            } finally {
                this.loading = false;
            }
        },
        editRow(payload) {
            this.modalVisible = true;
            this.editingData = payload;
        },
        async searchCompaniesDatabase(value) {
            this.entity.external_company = 0;
            this.entity.external_branch = 0;

            this.branches = await new UniverseTransaction(this.session).companyDBInfo(value);

            this.companies = [...new Map(this.branches.map((item) => [item['id_company'], item])).values()];
   
        },
        openNew() {
            this.entity = new BWCybersourceBranchProcessor(this.session);
        },
        async list() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                await this.entity.all();
                this.entities = await this.entity.allxDB(this.entity.external_database, this.entity.external_company, this.entity.external_branch);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                if (!this.modal) this.entities = await this.entity.all();

                if (this.id) {
                    this.entity.id = this.id;
                    let entity = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, entity);
                }
                this.databases = await new UniverseTransaction(this.session).companyDB();
                console.log(this.databases);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
    computed: {
        branchesByCompany() {
            let branches = [{ id_branch: 0, branch_name: 'TODAS LAS SUCURSALES' }];
            this.branches
                .filter((x) => x.id_company == this.entity.external_company)
                .forEach((x) => {
                    branches.push(x);
                });
            return branches.length == 1 ? [] : branches;
        },
        CompanyByDatabase() {
            let companies = [{ id_company: 0, company_name: 'TODAS LAS SUCURSALES' }];
            this.companies
                .filter((x) => x.name == this.entity.databases)
                .forEach((x) => {
                    companies.push(x);
                });
            return companies.length == 1 ? [] : companies;
        },
    },
};
</script>

<style scoped lang="scss">
.ddl-card-icon {
    margin-right: 10px;
}
.scrollable-table::-webkit-scrollbar {
	width: 5px;
    height: 8px;
	}

	/* Track */
.scrollable-table::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
.scrollable-table::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
.scrollable-table::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
</style>
